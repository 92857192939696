// import { Link } from "gatsby"
import React from "react"
// import PropTypes from "prop-types"
import classNames from "classnames"
import Drawer from "./drawer"

import roleFeatures from "../json/role-features.json"
import Svg from "./svg"
import { CDNBASE } from "../utils/maps"
// import PosShower from "./posShower"

import st from "./css/roleFeature.module.scss"

class RoleFeature extends React.Component {
  state = {
    curRole: "",
    curFea: "",
    curSub: {},
    subItem: {},
  }

  hdHash = launch => {
    const hash = window.location.hash
    if (hash) {
      const [role, id, si] = hash.replace("#", "").split("-")
      // console.log(role, id, si, launch)
      // this.hdRoleNav(role)
      // !!id && this.hdFeaNav(id, role)
      // !id && this.setCurRole(role)
      // !!id && this.setCurFea({ id, role })
      !!si &&
        this.setCurSub({ id, si: parseInt(si), role, launch: launch === 0 })
    }
  }
  hdScroll = () => {
    // const scrollTop = document.documentElement.scrollTop
    // console.log(scrollTop)
    const { isMobile } = this.props
    const top = isMobile ? 55 : 55
    let flag = false

    roleFeatures.forEach(({ icon, items }) => {
      const secRect = this.refs[icon].getClientRects()[0]

      const active = secRect.top < top && secRect.top + secRect.height > top
      let curFea = this.state.curFea
      if (active) {
        const roleHeadRect = this.refs[icon + "_head"].getClientRects()[0]
        flag = true
        items.forEach(v => {
          const ele = this.refs["stage_" + v.id]
          if (!ele) return
          const rect = ele.getBoundingClientRect()
          // console.log(rect.height);
          // console.log(roleHeadRect.height);
          if (
            rect.top - roleHeadRect.height > -(rect.height / 2) &&
            rect.top - roleHeadRect.height < rect.height / 2
          ) {
            curFea = v.id
          }
        })

        this.setState({
          // curSub: 0,
          curFea,
          curRole: icon,
        })
        this.hdVideo({
          id: curFea,
          si: this.state.curSub[curFea],
        })
      }
    })
    if (!flag) {
      this.setState({
        // curSub: -1,
        // curFea: '',
        curRole: "",
      })
      this.hdVideo({ id: "", si: -1 })
    }
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      if (window.location.hash) this.hdHash(0)
      window.onhashchange = this.hdHash
      document.onscroll = this.hdScroll
    }
  }
  componentWillUnmount() {
    document.onscroll = null
    window.onhashchange = null
  }

  hdVideo = ({ id, si, launch }) => {
    // console.log(id, si, launch)
    const prevVid = this.state.curVideo
    const curVid = this.refs[`video_${id}_${si || 0}`]
    // console.log(`video_${id}_${si}`, prevVid, curVid)
    prevVid && prevVid.pause()
    if (curVid && !launch) {
      curVid.play()
      this.setState({ curVideo: curVid })
    }
  }

  // setCurRole = role => {
  //   const feaID = roleFeatures.find(v => v.icon === role).items[0].id

  //   this.setState({ curRole: role, curFea: feaID })
  //   this.hdVideo({ id: feaID, si: this.state.curSub[feaID] })
  // }
  // setCurFea = ({ id, role }) => {
  //   this.setState({ curFea: id, curRole: role })
  //   this.hdVideo({ id, si: this.state.curSub[id] })
  // }
  setCurSub = ({ id, si, role, launch, click }) => {
    // console.log("setCurSub", id, si, role)
    clearTimeout(this.timeID)
    this.timeID = setTimeout(() => {
      this.setState({
        curSub: { ...this.state.curSub, [id]: si },
        curFea: id,
        curRole: role,
      })
      this.hdVideo({ id, si, launch })
      if (this.props.isMobile && click) {
        role = role || this.state.curRole
        // console.log("setCurSub", id, si, role)
        const subItem = roleFeatures
          .find(v => v.icon === role)
          .items.find(v => v.id === id).subItems[si]
        this.setState({ subItem })
      }
    }, 0)
  }
  // hdFeaNav = (feaID, icon) => {
  //   console.log("hdFeaNav", feaID, icon)
  //   this.setState({ curFea: feaID })
  //   const ele = this.refs["stage_" + feaID]
  //   console.log(ele)
  //   if (!ele) return

  //   this.hdVideo({ id: feaID, si: this.state.curSub[feaID] || 0 })

  //   const { isMobile } = this.props
  //   const roleHeadRect = this.refs[icon + "_head"].getClientRects()[0]
  //   console.log(roleHeadRect)
  //   console.log(
  //     document.documentElement.scrollTop +
  //       ele.getClientRects()[0].top -
  //       roleHeadRect.height -
  //       55 -
  //       (isMobile ? 30 : -30)
  //   )
  //   window.scrollTo(
  //     0,
  //     document.documentElement.scrollTop +
  //       ele.getClientRects()[0].top -
  //       roleHeadRect.height -
  //       55 -
  //       (isMobile ? 30 : -30)
  //   )

  //   //todo:auto show subitems
  // }
  // hdRoleNav = id => {
  //   console.log("hdRoleNav", id)
  //   const ele = this.refs[id]
  //   console.log(ele)
  //   if (!ele) return

  //   const feaID = roleFeatures.find(v => v.icon === id).items[0].id
  //   this.hdVideo({ id: feaID, si: this.state.curSub[feaID] || 0 })

  //   // console.log(ele)
  //   // console.log(this.props.scrollTop)
  //   // console.log(ele.getClientRects()[0].top)
  //   window.scrollTo(
  //     0,
  //     document.documentElement.scrollTop + ele.getClientRects()[0].top - 55 + 30
  //   )
  // }

  render() {
    const { curFea, curSub, curRole, subItem } = this.state
    const { isMobile } = this.props

    return (
      <div>
        {/* <div>
          <h2 className='f-tc f-mt-3'>酷课约课系统怎么用？</h2>
          <div className={st.iconNav}>
            {
              roleFeatures.map(v => (
                <div key={v.icon} className={st.iconNavItem}>
                  <div className={st.iconNavItemWrap} onClick={this.hdRoleNav.bind(null, v.icon)}>
                    <Svg svgClass={st.iconNavIcon} id={v.icon} />
                    <div className={st.mask}></div>
                    <div className={st.iconNavTitle}><h3>{v.title}</h3></div>
                  </div>
                </div>
              ))
            }
          </div>
        </div> */}
        <div className={st.wrapper}>
          <div className={st.sideLine}></div>
          {roleFeatures.map(({ icon, items }) => (
            <section
              id={icon}
              key={icon}
              className={classNames(st.section, { [st.active]: !!curRole })}
              ref={icon}
            >
              <div
                className={classNames(st.roleHead, {
                  [st.cur]: curRole === icon,
                })}
                ref={`${icon}_head`}
              >
                <div className={st.roleIcon}>
                  <div
                    className={st.roleAvatar}
                    style={{
                      backgroundImage: `url(${CDNBASE}/home/kuke_features/icons/${icon}.png)`,
                    }}
                  ></div>
                </div>
                <div className={st.role}>
                  <div className={st.roleOpts}>
                    {roleFeatures.map(v => {
                      if (v.icon === icon) {
                        return (
                          <div key={v.icon} className={st.roleCur}>
                            <h2>{v.title}</h2>
                          </div>
                        )
                      }
                      return (
                        <a
                          key={v.icon}
                          href={`/#${v.icon}`}
                          className={st.roleOpt}
                        >
                          {v.title}
                        </a>
                      )
                    })}
                  </div>
                  <div className={st.feaOpts}>
                    {!isMobile && <span className={st.ican}>我可以 </span>}
                    {items.map(v => (
                      <a
                        key={v.id}
                        className={classNames(st.feaBtn, {
                          [st.cur]: curFea === v.id,
                        })}
                        href={`/#${icon}-${v.id}`}
                      >
                        {v.fullName}
                      </a>
                    ))}
                  </div>
                </div>
              </div>

              <div className={st.stages}>
                {items.map(v => (
                  <div
                    key={v.id}
                    className={classNames(st.stage, {
                      [st.cur]: curFea === v.id,
                    })}
                    ref={"stage_" + v.id}
                  >
                    <i id={`${icon}-${v.id}`} className={st.stageTag}></i>
                    {v.subItems.map((_, si) => (
                      <i
                        key={si}
                        id={`${icon}-${v.id}-${si}`}
                        className={st.stageTag}
                      ></i>
                    ))}
                    <div className={st.feaWrap}>
                      <div className={st.feaBar}>
                        <div className={st.feaBarWrap}>
                          <Svg svgClass={st.feaSvg} id={v.icon || v.id} />
                        </div>
                        <h3>{v.name}</h3>
                      </div>
                      <div className={st.subItems}>
                        {v.subItems.map((sv, si) => (
                          <div
                            onClick={this.setCurSub.bind(null, {
                              id: v.id,
                              si,
                              role: icon,
                              click: true,
                            })}
                            key={sv.title + si}
                            className={classNames(st.subItem, {
                              [st.cur]: (curSub[v.id] || 0) === si,
                            })}
                          >
                            <h4>
                              <span className={st.dot}></span>
                              {sv.title}{" "}
                              {sv.video && (
                                <Svg svgClass={st.feaVideo} id="videoIcon" />
                              )}
                            </h4>
                            <p>{sv.desc}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                    {!isMobile && (
                      <div className={st.picsShow}>
                        {v.subItems.map((sv, si) => {
                          const image = (
                            <img
                              className={st.pic}
                              src={`${CDNBASE}/home/${v.id}/${si}.${
                                sv.picTyp || "png"
                              }?0133`}
                              alt={sv.title}
                            />
                          )

                          return (
                            <div
                              key={sv.title + si}
                              className={classNames(st.showWrap, {
                                [st.cur]: (curSub[v.id] || 0) === si,
                              })}
                            >
                              <div className={st.showBg}></div>
                              {sv.video ? (
                                <video
                                  ref={`video_${v.id}_${si}`}
                                  controls
                                  preload="metadata"
                                  width="100%"
                                  className={st.video}
                                >
                                  <source
                                    src={`https://cdn.kukecloud.com/assets/www/assets/video/${sv.video}`}
                                    type="video/mp4"
                                  />
                                  {/* {image} */}
                                </video>
                              ) : (
                                image
                              )}
                              {/* <h4 className={st.showTitle}>{sv.title}</h4> */}
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </section>
          ))}
        </div>

        <Drawer
          title={subItem.title}
          style={{ textAlign: "center" }}
          height="65%"
          placement="bottom"
          maskClosable={true}
          destroyOnClose={true}
          onClose={() => this.setState({ subItem: {} })}
          open={!!subItem.title}
        >
          {subItem.video ? (
            <video
              ref={`video_${curFea}_${curSub[curFea]}`}
              controls
              preload="metadata"
              width="100%"
              height="100%"
              className={st.videoDrawer}
            >
              <source
                src={`https://cdn.kukecloud.com/assets/www/assets/video/${subItem.video}`}
                type="video/mp4"
              />
            </video>
          ) : (
            <img
              className={st.picDrawer}
              src={`${CDNBASE}/home/${curFea}/${curSub[curFea]}.${
                subItem.picTyp || "png"
              }?0134`}
              alt={subItem.title}
            />
          )}
        </Drawer>
      </div>
    )
  }
}

export default RoleFeature
